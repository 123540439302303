import React, { useEffect } from "react";
import { graphql } from "gatsby";
import "../../assets/map/singleRegionMap.scss";
import PageWrapper from "../../components/PageWrapper";
import Seo from "./../../components/Seo";
import { navigate } from "gatsby-link";
import NetomniaRegions from "../../sections/index/NetomniaRegions";
import BlogList from "../../sections/blog/BlogList";

const StrapiRegion = (props) => {

  const Region = props.data.strapiRegions

  const Towns = props.data.strapiRegions.towns

	const contactText = props.data.strapiContactPage;

    useEffect(() => {
      if (Towns.length === 0 && window) navigate('/')
    }, [Towns]);
  
    return (
      <>
        <Seo page={Region.Slug} />      
        <PageWrapper footerDark>
          <div className="pt-5"/>
          <NetomniaRegions mapOnly={false} isNew={true} region={Region}/>
	  		</PageWrapper>
		  	<BlogList articles={props.data} enquiryHtml={contactText.enquiry_notice} page="news"/>
      </>
    );
}

export const data = graphql`
        query CombinedQuery($Slug: String) {
  strapiRegions(Slug: {eq: $Slug}) {
    Slug
    Name
    Viewbox
    towns {
      Region
      Name
      Slug
      region
      Featured_image {
        childImageSharp {
          fluid(maxHeight: 120, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  
  allStrapiNewsArticles(limit: 3, sort: {order: DESC, fields: backdate}) {
    edges {
      node {
        id
        strapiId
        backdate
        published_at
        title
        content
        slug
        image_featured {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  
  strapiContactPage {
    enquiry_notice
  }
  
  strapiPageHeadings(slug: { eq: "news" }) {
    h1
  }
}
    `

export default StrapiRegion
